
import { Component, Vue } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import { AreaList, DeviceList } from '@/types/irrigation'
import { PolygonStyle } from '@/types/common'

const soilInfo = [
  {
    title: '温度(°C)',
    value: '-',
    icon: 'icon-icon_shidu',
    status: ''
  },
  {
    title: '湿度(%)',
    value: '-',
    icon: 'icon-icon_wendu',
    status: ''
  }
]

const soilStatus = {
  temperature: '',
  humidity: ''
}

type Lnglat = {
  longitude: number;
  latitude: number;
}

@Component
export default class IrrigationArea extends Vue {
  areaList: AreaList[] = []
  currentIndex = 0
  info = {
    irrigationPlan: {},
    irrigationNum: {}
  }

  infoList = [{
    name: '开启总数',
    count: 0
  }, {
    name: '定时开启',
    count: 0
  }, {
    name: '智能开启',
    count: 0
  }, {
    name: '手动开启',
    count: 0
  }]

  private map: AMap.Map | any = null
  private ploygons: Array<AMap.Polygon> = []
  private marker: Array<AMap.Marker> = []
  // 电磁阀
  private valveList: DeviceList[] = []
  private soilList: DeviceList[] = []
  private waterList: DeviceList[] = []
  private electricityList: DeviceList[] = []
  private polygonStyle: PolygonStyle = {
    strokeColor: '#3592FE',
    fillColor: '#3592FE',
    strokeWeight: 4,
    strokeOpacity: 1,
    fillOpacity: 0.3,
    strokeStyle: 'solid',
    zIndex: 0
  }

  checkValue = ['土壤监测', '智慧水表', '智慧电表', '电磁阀']
  drawer = false
  tableData = []
  drawerData: DeviceList | null = {
    deviceNumber: '',
    deviceName: '',
    areaId: '',
    deviceType: '',
    longitude: '',
    latitude: '',
    deviceStatus: '',
    type: -1
  }

  private soilDetailInfo = {}
  // 土壤温湿
  onlineWenImg = require('@/assets/icon/soilHumidity/normal.svg')
  onlineWenImgCursor = require('@/assets/icon/soilHumidity/normal-big.svg')
  offWenImg = require('@/assets/icon/soilHumidity/offline.svg')
  offImgWenCursor = require('@/assets/icon/soilHumidity/offline-big.svg')
  alarmWenImg = require('@/assets/icon/soilHumidity/warning.svg')
  alarmWenImgCursor = require('@/assets/icon/soilHumidity/warning-big.svg')
  // 喷灌
  onlinePenImg = require('@/assets/icon/irrigation/icon_map_penguan_zaixian.svg')
  offPenImg = require('@/assets/icon/irrigation/icon_map_penguan_lixian.svg')
  onlinePenImgCursor = require('@/assets/icon/irrigation/icon_map_penguan_zaixian1.svg')
  offPenImgCursor = require('@/assets/icon/irrigation/icon_map_penguan_lixian1.svg')
  // 电
  onlineDianImg = require('@/assets/icon/irrigation/icon_map_dian_zaixian.svg')
  offDianImg = require('@/assets/icon/irrigation/icon_map_dian_zaixian1.svg')
  onlineDianImgCursor = require('@/assets/icon/irrigation/icon_map_dian_lixian.svg')
  offDianImgCursor = require('@/assets/icon/irrigation/icon_map_dian_lixian1.svg')
  // 水表
  onlineWaterImg = require('@/assets/icon/irrigation/icon_map_water_zaixian.svg')
  onlineWaterImgCursor = require('@/assets/icon/irrigation/icon_map_water_zaixian1.svg')
  offWaterImg = require('@/assets/icon/irrigation/icon_map_water_lixian.svg')
  offWaterImgCursor = require('@/assets/icon/irrigation/icon_map_water_lixian1.svg')

  private options = [{
    value: '10',
    label: '10分钟'
  }, {
    value: '15',
    label: '15分钟'
  }, {
    value: '20',
    label: '20分钟'
  }, {
    value: '40',
    label: '40分钟'
  }]

  // 电磁阀开启关闭状态加载
  private valveStatusUpdateLoading: any = null
  private irrigationInfo = {
    duration: '10'
  }

  get projectId () {
    return this.$route.query.projectId
  }

  get areaId () {
    return this.areaList.length > 0 ? this.areaList[this.currentIndex].areaId : ''
  }

  created () {
    // 获取电子围栏
    this.loadData().then(() => {
      // 获取区域
      this.getAreaList()
    })
  }

  destroyed () {
    if (this.marker) {
      this.marker.forEach((item: AMap.Marker) => {
        item.off('click', this.drawerOpen)
      })
    }
    if (this.ploygons) {
      this.ploygons.forEach(item => {
        item.off('click', this.drawerClose)
      })
    }
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }

  // 选中区域
  selectArea (index: number) {
    this.currentIndex = index
    // 查询
    this.getAllMarkersPost()
    // 首页
    this.selectIrrigationAreaIndex()
    // 区域
    this.selectIrrigationAreaByAreaId()
    this.drawerClose()
    this.map.setZoom(14)
  }

  // 获取区域
  getAreaList () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationAreaByPage, {
      projectId: this.projectId
    }).then(res => {
      this.areaList = res.list || []
      if (this.areaList.length > 0) {
        // 设备
        this.selectIrrigationValveByList()
        this.selectIrrigationSoilByList()
        this.selectIrrigationWaterByList()
        this.selectIrrigationElectricityByList()
        // 首页
        this.selectIrrigationAreaIndex()
        // 区域
        this.selectIrrigationAreaByAreaId()
      } else {
        this.valveList = []
        this.soilList = []
        this.waterList = []
        this.electricityList = []
        this.map.remove(this.ploygons)
        this.clearMarker()
      }
    })
  }

  addArea () {
    this.$router.push({
      path: '/irrigation/addArea',
      query: {
        projectId: this.projectId
      }
    })
  }

  updateArea (areaId: string) {
    this.$router.push({
      path: '/irrigation/updateArea' + areaId,
      query: {
        projectId: this.projectId
      }
    })
  }

  deleteArea (areaId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.irrigation.deleteIrrigationArea, {
        areaId: areaId
      }).then(() => {
        this.currentIndex = 0
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.getAreaList()
      })
    })
  }

  // 首页统计
  selectIrrigationAreaIndex () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationAreaIndex, {
      projectId: this.projectId,
      areaId: this.areaId
    }).then(res => {
      this.infoList[0].count = res.irrigationNum.allNum || 0
      this.infoList[1].count = res.irrigationNum.timeNum || 0
      this.infoList[2].count = res.irrigationNum.wisdomNum || 0
      this.infoList[3].count = res.irrigationNum.handNum || 0
      this.info = res
    })
  }

  // 获取电子围栏
  loadData () {
    return new Promise((resolve, reject) => {
      this.$axios.get(this.$apis.project.selectYhProjectMapByProjectId, {
        projectId: this.projectId
      }).then((res) => {
        !this.map && this.loadMap({ latitude: res.latitude, longitude: res.longitude }, res.locationList).then((res) => {
          resolve(res)
        })
      }).catch((res) => {
        reject(res)
      })
    })
  }

  // 获取区域围栏
  selectIrrigationAreaByAreaId () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationAreaByAreaId, {
      projectId: this.projectId,
      areaId: this.areaId
    }).then((res) => {
      if (this.map) {
        this.map.remove(this.ploygons)
        this.ploygons = drawPolygon(this.map, res.locationList, this.polygonStyle, null)
      }
    })
  }

  markerAdd (item: DeviceList, index: number) {
    item.type = index
    const marker: any = new AMap.Marker({
      icon: new AMap.Icon({
        // 图标的取图地址
        // ['土壤监测', '智慧水表', '智慧电表', '电磁阀']
        image: index === 3 ? this.getIconPen(item, false) : this.getIcon(item, false)
      }),
      // offset: new AMap.Pixel(-10, -14),
      offset: new AMap.Pixel(-18, -48),
      position: [Number(item.longitude), Number(item.latitude)]
    })
    this.map.add(marker)
    marker.detail = {
      ...item,
      type: index
    }
    marker.on('click', this.drawerOpen)
    this.marker.push(marker)
  }

  clearMarker () {
    this.map && this.map.remove(this.marker)
  }

  // 抽屉打开
  drawerOpen (e: any) {
    const detail = e.target.detail
    let iconClick: any = null
    if (this.drawerData === null || (this.drawerData && this.drawerData.deviceNumber !== detail.deviceNumber)) {
      this.drawerClose()
      this.drawerData = detail
      // 点击切换大图标
      switch (detail.type) {
        case 0:
        case 1:
        case 2:
          iconClick = this.getIcon(detail, true)
          this.openSoilDevice(detail)
          this.getSoilTable(detail.deviceNumber)
          break
        case 3:
          iconClick = this.getIconPen(detail, true)
          break
      }
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(iconClick)
    }
    this.drawer = true
  }

  openSoilDevice (detail: any) {
    // 温度
    soilInfo[0].value = detail.temperature || '--'
    soilInfo[0].status = this.getTemperatureStatus(detail.temperatureStatus)
    // 湿度
    soilInfo[1].value = detail.humidity || '--'
    soilInfo[1].status = this.getHumidityStatus(detail.humidityStatus)
    this.soilDetailInfo = soilInfo
  }

  getSoilTable (deviceNumber: string) {
    this.$axios.get(this.$apis.ecologyMonitor.selectSoilDataByPage, {
      deviceNumber: deviceNumber,
      page: 1,
      size: 3,
      notDesc: '1'
    }).then((res) => {
      this.tableData = res.soil.list || []
    })
  }

  getTemperatureStatus (value: string) {
    switch (value) {
      case '0':
        return '正常'
      case '1':
        return '过热'
      case '2':
        return '冰冻'
      default:
        return '--'
    }
  }

  getHumidityStatus (value: string) {
    switch (value) {
      case '0':
        return '正常'
      case '1':
        return '过涝'
      case '2':
        return '干旱'
      case '3':
        return '极度干旱'
      default:
        return '--'
    }
  }

  // 处理图标状态 bigShow:是否大图标
  getIcon (data: DeviceList, bigShow: boolean) {
    let icon
    if (data.type === 0) {
      if (data.deviceStatus === '1') {
        icon = data.runStatus === '1' ? (bigShow ? this.alarmWenImgCursor : this.alarmWenImg) : (bigShow ? this.onlineWenImgCursor : this.onlineWenImg)
      } else if (data.deviceStatus === '2') {
        icon = bigShow ? this.offImgWenCursor : this.offWenImg
      }
      return icon
    } else if (data.type === 2) {
      if (data.deviceStatus === '1') {
        icon = bigShow ? this.onlineDianImg : this.onlineDianImgCursor
      } else if (data.deviceStatus === '2') {
        icon = bigShow ? this.offDianImg : this.offDianImgCursor
      }
    } else if (data.type === 1) {
      if (data.deviceStatus === '1') {
        icon = bigShow ? this.onlineWaterImg : this.onlineWaterImgCursor
      } else if (data.deviceStatus === '2') {
        icon = bigShow ? this.offWaterImg : this.offWaterImgCursor
      }
    }
  }

  // 处理图标状态 bigShow:是否大图标 开启关闭
  getIconPen (data: DeviceList, bigShow: boolean) {
    let icon
    // 在线
    if (data.switchStatus === '1') {
      icon = bigShow ? this.onlinePenImgCursor : this.onlinePenImg
    } else if (data.switchStatus === '0') {
      // 离线
      icon = bigShow ? this.offPenImgCursor : this.offPenImg
    }
    return icon
  }

  // 打点
  getAllMarkersPost () {
    this.clearMarker()
    this.selectIrrigationValveByList()
    this.selectIrrigationSoilByList()
    this.selectIrrigationWaterByList()
    this.selectIrrigationElectricityByList()
  }

  // 电磁阀开启关闭
  operationIrrigation (command: string, deviceNumber: string) {
    this.valveStatusUpdateLoading = this.$loading({
      lock: true,
      text: command === '1' ? '电磁阀正在开启' : '电磁阀正在关闭',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    // 0 是关闭
    const info = command === '1' ? { execLength: this.irrigationInfo.duration } : {}
    this.$axios.post(this.$apis.irrigation.insertIrrigationValveRecord, {
      deviceNumber: deviceNumber,
      command: command,
      ...info
    }).then(() => {
      if (this.map && this.drawerData) {
        const deviceNumber = this.drawerData.deviceNumber || ''
        const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceNumber === deviceNumber)
        this.drawerData.switchStatus = command
        const icon = this.getIconPen(this.drawerData, true)
        const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
        if (this.drawerData.longitude && this.drawerData.latitude) {
          marker.setOffset(new AMap.Pixel(-22, -64))
          marker.setIcon(icon)
        }
      }
      this.$message.success((command === '1' ? '开启' : '关闭') + '成功')
    }).catch(() => {
      this.$message.success((command === '1' ? '开启' : '关闭') + '失败')
    }).finally(() => {
      if (this.valveStatusUpdateLoading) {
        this.valveStatusUpdateLoading.close()
      }
    })
  }

  // 关闭抽屉
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      this.iconTab()
      this.drawerData = null
    }
  }

  // 替换图标
  iconTab () {
    if (this.map && this.drawerData) {
      const deviceNumber = this.drawerData.deviceNumber || ''
      const index = this.map.getAllOverlays('marker').findIndex((item: any) => item.detail.deviceNumber === deviceNumber)
      const icon = this.drawerData.type === 3 ? this.getIconPen(this.drawerData, false) : this.getIcon(this.drawerData, false)
      const marker = this.map.getAllOverlays('marker')[index] as AMap.Marker
      if (this.drawerData.longitude && this.drawerData.latitude) {
        marker.setOffset(new AMap.Pixel(-18, -48))
        marker.setIcon(icon)
      }
    }
  }

  // 喷灌土壤设备
  selectIrrigationSoilByList () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationSoilByList, {
      areaId: this.areaId,
      projectId: this.projectId
    }).then((res) => {
      this.soilList = res || []
      if (this.checkValue.includes('土壤监测')) {
        this.soilList.forEach((item) => {
          this.markerAdd(item, 0)
        })
      }
    })
  }

  // 喷灌水表设备
  selectIrrigationWaterByList () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationWaterByList, {
      areaId: this.areaId,
      projectId: this.projectId
    }).then((res) => {
      this.waterList = res || []
      if (this.checkValue.includes('智慧水表')) {
        this.waterList.forEach((item) => {
          this.markerAdd(item, 1)
        })
      }
    })
  }

  // 查询喷灌电表设备列表
  selectIrrigationElectricityByList () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationElectricityByList, {
      areaId: this.areaId,
      projectId: this.projectId
    }).then((res) => {
      this.electricityList = res || []
      if (this.checkValue.includes('智慧电表')) {
        this.electricityList.forEach((item) => {
          this.markerAdd(item, 2)
        })
      }
    })
  }

  // 喷灌电磁阀
  selectIrrigationValveByList () {
    this.$axios.get(this.$apis.irrigation.selectIrrigationValveByList, {
      areaId: this.areaId,
      projectId: this.projectId
    }).then((res) => {
      this.valveList = res || []
      if (this.checkValue.includes('电磁阀')) {
        this.valveList.forEach((item) => {
          this.markerAdd(item, 3)
        })
      }
    })
  }

  // 地图初始化
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    return new Promise((resolve, reject) => {
      AMapLoader.load({
        key: mapKey,
        version: '2.0',
        plugins: []
      }).then(() => {
        const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
        const map = new AMap.Map('mapContainer', {
          center: center,
          zoom: 11
        })
        this.map = map
        // 点击地图关闭抽屉
        this.map.on('click', this.drawerClose)
        const ploygons = drawPolygon(this.map, list, null, null)
        ploygons[0].on('click', this.drawerClose)
        resolve(map)
      }).catch((res) => {
        reject(res)
      })
    })
  }
}
